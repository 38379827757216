jQuery(function($) {
  jQuery(document).ready(function() {
    var phoneElement = document.getElementById("billing_phone");
    var billing_postcode = document.getElementById("billing_postcode");
    var shipping_postcode = document.getElementById("shipping_postcode");
    var billing_country = $("#billing_country");
    var shipping_country = $("#shipping_country");
    var maskOptions = {
      mask: "(000) 000-0000",
    };
    if (phoneElement) {
      IMask(phoneElement, maskOptions);
    }

    var postalCode = {
      mask: "a0a 0a0",
      prepare: function(str) {
        return str.toUpperCase();
      },
    };
    var zipCode = {
      // mask: /(^\d{5}$)|(^\d{5}-\d{4}$)/
      mask: "00000-0000",
    };
    var bpm, spm;
    if (billing_postcode) {
      if ("US" === billing_country.find(":selected").val()) {
        bpm = IMask(billing_postcode, zipCode);
      } else {
        bpm = IMask(billing_postcode, postalCode);
      }
    }
    if (shipping_postcode) {
      if ("US" === shipping_country.find(":selected").val()) {
        spm = IMask(shipping_postcode, zipCode);
      } else {
        spm = IMask(shipping_postcode, postalCode);
      }
    }

    $("#billing_country").on("change", function() {
      $("#billing_postcode").val("");
      bpm.updateValue("");
      var selectedCountry = $(this).val();
      if ("US" === selectedCountry) {
        // bpm.destroy();
        // bpm = IMask(billing_postcode, zipCode);
        bpm.updateOptions(zipCode);
      } else {
        // bpm.destroy();
        // bpm = IMask(billing_postcode, postalCode);
        bpm.updateOptions(postalCode);
      }
    });

    $("#shipping_country").on("change", function() {
      $("#shipping_postcode").val("");
      spm.updateValue("");
      var selectedCountry = $(this).val();
      if ("US" === selectedCountry) {
        // spm.destroy();
        // spm = IMask(shipping_postcode, zipCode);
        spm.updateOptions(zipCode);
      } else {
        // spm.destroy();
        // spm = IMask(shipping_postcode, postalCode);
        spm.updateOptions(postalCode);
      }
    });

    $(".read_more_text").click(function() {
      if ($(".description_more").is(":visible")) {
        $(".description_more").hide();
        $("table.attributes").hide();
        $(".read_more_class_description").show();
        $(this).find("span").html("Read More");
      } else {
        $(".description_more").show();
        $("table.attributes").show();
        $(".read_more_class_description").hide();
        $(this).find("span").html("Read Less");
      }
    });

    $(".woocommerce-form-login-toggle > .woocommerce-info").css(
      "border-bottom",
      "1px solid #d9d9d9",
    );
    $(".woocommerce-form-coupon-toggle > .woocommerce-info").css(
      "border-bottom",
      "1px solid #d9d9d9",
    );

    $(".showcoupon").click(function() {
      if ($(".checkout_coupon").is(":visible")) {
        $(".woocommerce-form-coupon-toggle > .woocommerce-info").css(
          "border-bottom",
          "1px solid #d9d9d9",
        );
      } else {
        $(".woocommerce-form-coupon-toggle > .woocommerce-info").css(
          "border-bottom",
          "none",
        );
      }
      $(".checkout_coupon").slideToggle(400, function() {
        $(".checkout_coupon").find(":input:eq(0)").trigger("focus");
      });
    });

    $(".showlogin").click(function() {
      if ($(".woocommerce-form-login").is(":visible")) {
        $(".woocommerce-form-login-toggle > .woocommerce-info").css(
          "border-bottom",
          "1px solid #d9d9d9",
        );
      } else {
        $(".woocommerce-form-login-toggle > .woocommerce-info").css(
          "border-bottom",
          "none",
        );
      }
      $("form.login, form.woocommerce-form--login").slideToggle();
    });

    $(document).on("change", "[name=po_new_customer_field]", function() {
      if ($(this).val() == "on") {
        $("#po_number_field").val("N/A");
        $("#po_number_field").prop("readonly", true);
      } else {
        $("#po_number_field").val("");
        $("#po_number_field").prop("readonly", false);
      }
    });

    $(document.body).on("updated_checkout", function() {
      if ($("[name=po_new_customer_field]").val() == "on") {
        $("#po_number_field").val("N/A");
        $("#po_number_field").prop("readonly", true);
      } else {
        if ($("#po_number_field").val() == "N/A") {
          $("#po_number_field").val("");
        }
        $("#po_number_field").prop("readonly", false);
      }
    });

    $(document).on("click", "#place_order", function() {
      $("body").loading_screen(true, function() { });
    });

    // $(document).on("click", "#moneris_place_order", function() {
    //   // $('body').loading_screen( true, function() { } );
    //   monerisChecker();
    // });

    // function monerisChecker() {
    //   setTimeout( (function() {
    //     // console.log("timeout checked");
    //     if ( $("#monerisCheckout").html != "" ) {
    //       $('body').loading_screen( false, function() { } );
    //     } else {
    //       monerisChecker();
    //     }
    //   }), 5000 );
    // }

    $(document.body).on("checkout_error", function() {
      $("body").loading_screen(false, function() { });
    });

    // Overwrite WooCommerce scroll to notices
    jQuery(document).on("checkout_error", function() {
      var offset = 300; // Adjust this value as needed
      var notices = jQuery(".woocommerce-NoticeGroup-checkout");

      if (notices.length) {
        jQuery("html, body").animate(
          {
            scrollTop: notices.offset().top - offset,
          },
          1000,
        );
      }
    });

    // function checkout_button_text() {
    //   if ( $('input[name^="payment_method"]:checked').val() == 'payment_method_woocommerce_gateway_purchase_order' ) {
    //     $('input[name^="woocommerce_checkout_place_order"]').val('Place Order');
    //   } else {
    //     $('input[name^="woocommerce_checkout_place_order"]').val('Continue to Payment');
    //   }
    // }

    // checkout_button_text();

    // $( 'form.checkout' ).on( 'change', 'input[name^="payment_method"]', function() {
    //   checkout_button_text();
    // });

    // $( 'document.body' ).on( 'updated_checkout', 'input[name^="payment_method"]', function() {
    //   checkout_button_text();
    // });

    /*
    setTimeout(() => {
      $(".more_info .btn").click(function () {
        $("#place_order").trigger("submit");
      });
    }, 4000);*/

    $(".quantity").on("click", ".plus", function(e) {
      var $input = $(this).siblings(".qty");
      var val = parseInt($input.val());
      var max = parseInt($input.attr("max"));
      if (!isNaN(val) && (isNaN(max) || val < max)) {
        $input.val(val + 1).change();
      }
    });

    $(".quantity").on("click", ".minus", function(e) {
      var $input = $(this).siblings(".qty");
      var val = parseInt($input.val());
      var min = parseInt($input.attr("min"));
      if (!isNaN(val) && (isNaN(min) || val > min)) {
        $input.val(val - 1).change();
      }
    });

    $(document).on("change", "#stock_check", function() {
      var checked = $(this).prop("checked");
      // clean url
      var pageUrl = window.location.href
        .replace(window.location.hash, "")
        .replace(window.location.search, "")
        .replace("#", "")
        .replace("?", "");
      var search = window.location.search
        .replace("?", "")
        .replace("stock=true", "")
        .replace("orderby=", "ob=");
      if (search[0] == "&") {
        search = search.substring(1);
      }

      if (checked) {
        window.location.href =
          pageUrl + "?" + search + "&stock=true&orderby=price-desc";
      } else {
        window.location.href = pageUrl + (search ? "?" + search : "");
      }
    });
  });
});
