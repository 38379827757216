jQuery(function ($) {

  jQuery(window).on('resize', function() {
    jQuery('html').click();
  });

  jQuery(window).on('scroll', function() {
    jQuery('html').click();
  });
});
