jQuery(function ($) {
  jQuery(document).ready(function () {
    //hide all of the answer
    $(".faq_section .answer").hide();

    $(".nav-link").click(function () {
      $(".question").attr("style", "display:flex !important");
    });

    $("#searh_input").keyup(function (event) {
      var search_value = $("#searh_input").val();
      $(".tab-pane").addClass("show");
      $(".question").attr("style", "display:flex !important");
      $(".no_result").hide();
      if (!search_value) return;
      $(".question").each(function () {
        var question_test = $(this).find(".question_text")[0].innerHTML;
        var answer_test = $(this).find(".answer_text")[0].innerHTML;
        if (
          !question_test.toLocaleLowerCase().includes(search_value) &&
          !answer_test.toLocaleLowerCase().includes(search_value)
        ) {
          $(this).attr("style", "display:none !important");
          $(this).hide();
        }
      });
      if (!$(".question").is(":visible")) {
        $(".no_result").show();
      }
    });

    $(".faq_section .question").click(function () {
      if ($(this).find(".answer").is(":visible")) {
        $(this).find(".answer").hide();
        $(this).find("i").removeClass("fa-minus").addClass("fa-plus");
      } else {
        $(this).find(".answer").show();
        $(this).find("i").removeClass("fa-plus").addClass("fa-minus");
      }
    });
  });
});
