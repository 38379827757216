jQuery(function ($) {
  let scrollPosition = 100;
  let bodyPosition = 0;
  $(window).on("scroll", function () {
    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    // console.log("show",$('.searchwp-live-search-results-showing').length);
    if ($(".searchwp-live-search-results-showing").length == 0) {
      if (st > scrollPosition) {
        // downscroll code
        // console.log(st - scrollPosition);
        if (st - scrollPosition >= 10 || $(".header-search").height() === 0) {
          //$(".header-search").addClass("scroll-hide");
          //jQuery("#s").data("plugin_searchwp_live_search").destroy_results();
        }
      } else if (st < scrollPosition) {
        // upscroll code
        //$(".header-search").removeClass("scroll-hide");
      }
      //headerPadding();
    } else {
      return false;
    }
    scrollPosition = st <= 0 ? 0 : st;
  });

  $(window).on("resize", function () {
    headerPadding();
    if ($(window).width() > 500) {
      $("body").removeClass("lock");
    }
    // jQuery('#s').data("plugin_searchwp_live_search").destroy_results();
  });

  $(document).on("click", ".hamburger", function () {
    $(this).toggleClass("is-active");
    $("header").toggleClass("is-active");
    $(".header-nav").toggleClass("is-active");
    // jQuery("#s").data("plugin_searchwp_live_search").destroy_results();
    $("#search-results").removeClass("show");
    $("#loading-indicator").hide();
    if ($("header").hasClass("is-active")) {
      bodyPosition = window.pageYOffset;
      if ($(window).width() < 501) {
        $("body").addClass("lock");
      }
    } else {
      $("body").removeClass("lock");
      window.scrollTo(0, bodyPosition);
    }
  });

  $(document).on("click", ".menu-item-has-children", function (e) {
    if ("A" === e.target.tagName) {
      return true;
    }

    $(this).toggleClass("hoverMimic");
  });

  function headerPadding() {
    $("body").css("padding-top", $(".header_fixed").height() + "px");
  }

  headerPadding();

  function searchShower() {
    var promotionLoyal = 0;
    if (jQuery(".loyalty_program_header")) {
      promotionLoyal = jQuery(".loyalty_program_header").height();
    }
    var top =
      jQuery(".header_fixed .upper_header").height() + promotionLoyal - 10;
    var width = jQuery(".search_input").width();
    $(".searchwp-live-search-results").css({
      top: top + "px",
      width: width + "px",
    });
    if (
      $(".searchwp-background").length == 0 &&
      $(".searchwp-live-search-results-showing").length > 0
    ) {
      $("body").append('<div class="searchwp-background"></div>');
      bodyPosition = window.pageYOffset;
    }
  }

  $(document).on("searchwp_live_position_results", function () {
    searchShower();
  });

  $(document).on("searchwp_live_show_spinner", function () {
    searchShower();
  });

  $(document).on("searchwp_live_destroy_results", function () {
    if ($(".searchwp-background").length != 0) {
      $(".searchwp-background").remove();
      window.scrollTo(0, bodyPosition);
    }
  });

  $(document).on("click", ".searchwp-background", function () {
    // jQuery("#s").data("plugin_searchwp_live_search").destroy_results();
  });

  // 20230113 - experimented on edging sub-nav
  // $(".header-nav ul.menu li.menu-item-has-children").on('mouseenter mouseleave', function (e) {
  //   if ($('ul.sub-menu', this).length) {
  //       var elm = $('ul:first', this);
  //       var off = elm.offset();
  //       var l = off.left;
  //       var w = elm.width();
  //       // var docH = $(".header-nav .container").height();
  //       var docW = $(".header-nav .container").width();

  //       var isEntirelyVisible = (l + w <= docW);

  //       if (!isEntirelyVisible) {
  //           $(this).addClass('edge');
  //       } else {
  //           $(this).removeClass('edge');
  //       }
  //   }
  // });

  function debounce(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  const debouncedSearch = debounce(performAjaxSearch, 300);
  $("#s").on("keyup", debouncedSearch);

  function performAjaxSearch() {
    if ($(window).width() < 501) {
      $("body").addClass("lock");
    }
    var searchQuery = $("#s").val();
    if (searchQuery.length > 2) {
      if ($("#close-search-results").hasClass('hidden')) {
        $("#close-search-results").removeClass('hidden');
      }
      $("#loading-indicator").show();
      let ajaxObj = ThemeJS.Functions.send_ajax_request_get_ajax_obj({
        data: {
          action: "perform_search",
          query: searchQuery,
        },
        success: (response) => {
          $("#search-results").html(response).addClass("show");
          $("#loading-indicator").hide();
        },
        error: (response) => {
          console.log(response);
        },
      });
      $("#close-search-results").on("click", function () {
        $("body").removeClass("lock");
        $("#search-results").removeClass("show").html('');
        $("#loading-indicator").hide();
        $("#s").val('');
        ajaxObj.abort();
        $("#close-search-results").addClass('hidden');
      });
    } else {
      $("#loading-indicator").hide();
      $("#search-results").empty().removeClass("show");
    }

  }
  $("#s").on("click", function () {
    if ($("#search-results").html()) {
      $("#search-results").addClass("show");
    }
  });

  let isScrolling = false;

  $(window).on("scroll", function () {
    isScrolling = true;
    setTimeout(function () {
      isScrolling = false;
    }, 250);
  });

  $(document).on("click", function (e) {
    if (isScrolling) {
      return;
    }
    if ($(window).width() > 500) {
      if (!$(e.target).closest("#search-form").length) {
        $("#search-results").removeClass("show");
        $("#loading-indicator").hide();
        $("body").removeClass("lock");
      } else {
        $("#search-results").addClass("show");
        // $("body").addClass("lock");
      }
    }
  });
});
